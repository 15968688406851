html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  /* background-attachment: fixed;
  background-image: url('/bg.svg'); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

body {
  margin: 0;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* paddingBottom= {isDevicePwa ? '0.2rem' : '0'} */
  /* white; currently this impacts navbar and any new components w/o bg colors */
  /* background-color: #FFFFFF; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
